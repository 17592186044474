<template>
<div class="container">
  <ScrollNotice />
  <div class="options">
    <div class="option_tags">
      <span class="label">您已选择</span>
      <el-divider direction="vertical"></el-divider>
      <div>
        <el-tag
            v-for="item in selectTags"
            :key="item"
            effect="plain"
            style="margin-right: 25px"
            size="mini"
            closable
            @close="handleClose(tag)"
        >
          {{ item }}
        </el-tag>
      </div>
    </div>
    <div class="option_select">
      <div class="option_more">
        <el-button class="label" type="text">1更多选择</el-button>
        <el-divider direction="vertical"></el-divider>
        <div class="option_item">
          <el-select v-model="options.domestic" placeholder="地区国内" size="mini" class="el-button-group short">
            <el-option label="全部" value="0"></el-option>
            <el-option label="地区" value="1"></el-option>
            <el-option label="西医" value="2"></el-option>
            <el-option label="中医" value="3"></el-option>
          </el-select>
          <el-select v-model="options.education" placeholder="地区国内" size="mini" class="el-button-group short">
            <el-option label="全部" value="0"></el-option>
            <el-option label="学历" value="1"></el-option>
            <el-option label="西医" value="2"></el-option>
            <el-option label="中医" value="3"></el-option>
          </el-select>
        </div>
      </div>
      <div class="date">
        <el-date-picker
            v-model="options.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-DD"
        >
        </el-date-picker>
      </div>
      <el-button icon="el-icon-search">
        搜索
      </el-button>
    </div>
    <div class="tab_wrap">
      <el-tabs ref="tabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="招聘" name="first" stretch="true">
          <div class="jobs">
            <template v-for="i in recruitList">
              <JobCard :key="i" @click.native="handleJob(i.uid)" :info="i" />
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="求职" name="second" stretch="true">
          <div class="jobs">
            <template v-for="i in resumeList">
              <SeekerCard :key="i" @click.native="handleSeeker(i.uid)" :info="i" />
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div class="page">
      <el-pagination
          background
          layout="prev, pager, next"
          :total="1000"
      >
      </el-pagination>
    </div> -->
  </div>
</div>
</template>

<script>
  import axios from "axios"; 
  import ScrollNotice from '@/components/ScrollNotice'
  import JobCard from '@/components/JobCard'
  import SeekerCard from '@/components/SeekerCard'
  export default {
    name: "RecruitmentIndex",
    components: { ScrollNotice, JobCard, SeekerCard },
    data() {
      return {
        selectTags: ['山东', '管科'],
        options: {
          domestic: '1',  // 国内
          education: '1',  // 学历
          date: ''
        },
        activeName: 'second',
		recruitList:[],
		resumeList:[],
      }
    },
    mounted() {
    },
    methods: {
      handleClose(tag) {
        this.selectTags.splice(this.selectTags.indexOf(tag), 1);
      },
      handleClick(tab, event) {
        console.log(tab, event);
 
		
		const params = new URLSearchParams();
		params.append('uid', this.id); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getRecruitList',params)
		.then((response) => {  
			console.log(response.data.data);
			this.recruitList = response.data.data; 
					  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		
		const params2 = new URLSearchParams();
		params2.append('uid', this.id); 
		console.log(params);
		axios.post(this.apiUrl+'/api/user/getResumeList',params2)
		.then((response) => {  
			console.log(response.data.data);
			this.resumeList = response.data.data; 
					  
		}) 
		.catch(function(error) {
		  console.log(error);
		});
		
		
      },
      handleJob(uid) {
		  //alert(uid);
        // this.$router.push({name: '招聘详情'})
        let routeData = this.$router.resolve({
          name: "招聘详情",
        });
        //window.open(routeData.href+"?id="+uid, '_blank');
        window.open(routeData.href+"?id="+uid);
      },
      handleSeeker(uid) { 
		  
		  //alert(uid);
        this.$router.push({name: '求职者信息'})
        let routeData = this.$router.resolve({
          name: "求职者信息",
          // query: params,
          // params:{catId:params.catId}
        });
        window.open(routeData.href+"?id="+uid, '_blank');
      }
    }
  }
</script>

<style scoped lang="scss">
  .container{
    .options{
      width: 1200px;
      margin: 20px auto;
      .option_tags{
        height: 40px;
        width: 1080px;
        padding-left: 120px;
        background-color: #fff;
        margin-bottom: 10px;
        position: relative;
        line-height: 40px;
        .el-tag--plain{
          color: $green;
          padding: 0 8px 0 14px;
          border-radius: 1px;
          border-color: $green;
          line-height: 18px;
        }
      }
      .option_select{
        height: 40px;
        width: 1200px;
        margin-bottom: 10px;
        position: relative;
        display: flex;
        flex-direction: row;
        .option_more{
          padding-left: 120px;
          height: 100%;
          width: 348px;
          margin-right: 6px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          background-color: #fff;
          .el-button--mini{
            padding: 4px 15px;
            border-color: #dedede!important;
            border-radius: 1px;
            color: #3e3e3e!important;
          }
          .el-button-group{
            margin-right: 52px;
            color: #333!important;
          }
        }
        .date{
          height: 40px;
          line-height: 40px;
          background-color: #fff;
          margin-right: 6px;
          .el-input__inner{
            border: none!important;
          }
        }
        .el-button.el-button--default.el-button--small{
          width: 105px;
          border-radius: 2px;
        }
      }
      .label{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 28px;
        font-size: 12px;
        /*font-family: "PingFang SC";*/
        color: #333;
        font-weight: normal;
      }
      .el-divider{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 102px;
        margin: 0;
        height: 26px;
        background-color: #f7f7f7;
      }
      .tab_wrap{
        .jobs{
          width: 1200px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      .page{
        width: 1200px;
        display: flex;
        justify-content: center;
        margin: 30px 0 60px;
      }
    }
  }
  /*下拉框宽度*/
  /deep/  .short.el-select .el-input{
    width: 108px;
  }
  /*下拉框高度*/
  /deep/ .el-input--mini .el-input__inner{
    height: 20px;
    line-height: 20px;
  }
  /deep/ .el-input--mini .el-input__icon{
    line-height: 20px;
    color: #333;
  }
  /deep/ .el-date-editor .el-range-input{
    letter-spacing: 1px;
  }
  /*tab*/
  .tab_wrap /deep/ .el-tabs__header{
    background-color: #fff;
    margin: 10px 0 20px;
    /*padding: 0 56px;*/
    border-radius: 4px;
  }
  .tab_wrap /deep/ .el-tabs__nav{
    /*width: 205px;*/
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: space-between;*/
  }
  .tab_wrap /deep/ .el-tabs__item{
    width: 65px;
    text-indent: 18px;
    text-align: center;
    padding: 0 75px!important;
}
  .tab_wrap /deep/ .el-tabs__nav-wrap::after{
    height: 0;
  }
  .tab_wrap /deep/ .el-tabs__active-bar{
    width: 65px!important;
  }
</style>
