<template>
  <div class="container" :style="{width: width,height: height,boxSizing: 'border-box'}">
    <div class="head">
      <div class="head_title">
        <span>{{info.title}}骨科医生</span>
        <img :src="require('@/assets/images/message.jpg')">
      </div>
      <div class="head_price">
        {{info.price}}面议
      </div>
    </div>
    <div class="education">
      {{info.education}}本科
    </div>
    <el-divider />
	
    <div class="foot">
      <div class="company">
        <el-image :src="info.upic"></el-image>
        <span class="ellipse">{{info.company}}山东医疗公司</span>
      </div>
      <div class="address ellipse">
        {{info.address}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'JobCard',
	
    props: {
      info: {
        type: Object,
        default: function () {
          return {
            title: '骨科医生',
            price: '面议',
            education: '本科',
            company: '山东医疗公司',
            address: '山东.济南.二环东路',
            pic: require('@/assets/avatar/弘淑.jpg')
          }
        }
      },
      width: {
        type: String,
        default: '385px'
      },
      height: {
        type: String,
        default: '154px'
      }
    }
  }
</script>

<style scoped lang="scss">
  .container{
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    &:hover{
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .head{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .head_title{
        height: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
          margin-right: 10px;
        }
      }
      .head_price{
        width: 100px;
        color: $red;
      }
    }
    .education{
      font-size: 12px;
    }
    .el-divider--horizontal{
      margin: 0;
      background-color: #eee!important;
    }
    .foot{
      font-size: 12px;
      height: 40px;
      line-height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .company{
        .el-image{
          width: 40px;
          height: 40px;
        }
        span{
          height: 40px;
          line-height: 40px;
          display: inline-block;
          margin-left: 12px;
          width: 160px;
        }
      }
      .address{
        width: 100px;
      }
    }
  }
</style>
