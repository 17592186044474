<template>
  <div class="container" :style="{width: width,height: height,boxSizing: 'border-box'}">
    <div class="pic">
      <el-image :src="info.upic" style="width: 100%;height: 100%" fit="cover"/>
    </div>
    <div class="info">
      <div class="title">
        <span class="name">{{info.name}}</span>
        <img :src="require('@/assets/images/message.jpg')">
        <span class="price">{{info.price}}</span>
      </div>
      <div class="info_item">年龄：{{info.age}}</div>
      <div class="info_item">学历：{{info.education}}</div>
      <div class="info_item">求职：{{info.post}}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SeekerCard',
    props: {
      info: {
        type: Object,
        default: function () {
          return {
            name: '王医生',
            price: '面议',
            education: '本科',
            age: '28岁',
            post: '外科医生',
            pic: require('@/assets/avatar/弘淑.jpg')
          }
        }
      },
      width: {
        type: String,
        default: '385px'
      },
      height: {
        type: String,
        default: '154px'
      }
    }
  }
</script>

<style scoped lang="scss">
.container{
  padding: 19px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  &:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .pic{
    width: 114px;
    height: 114px;
    margin-right: 10px;
  }
  .info{
    height: 114px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title{
      display: flex;
      flex-direction: row;
      align-items: center;
      img{
        margin: 0 30px;
      }
      .price{
        color: $red;
      }
    }
    .info_item{
      font-size: 12px;
      color: #666;
    }
  }
}
</style>
